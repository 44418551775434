<template>
  <div>
    <v-navigation-drawer app class="dont-print-header" v-model="drawer">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar height="90%" width="90%">
            <v-img
              alt="Vuetify Name"
              class="mt-1"
              contain
              :src="
                company.logo_path
                  ? `${mediaUrl + company.logo_path}`
                  : require('../../assets/logo-yarn.png')
              "
              width="100"
              height="100"
            />
          </v-list-item-avatar>
        </v-list-item>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{
                  user.first_name != null
                    ? user.first_name + " " + user.last_name
                    : user.email
                }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ DisplayName() }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <v-list-item
            v-if="
              roleName == 'ADMIN' ||
              (roleName == 'SUPER ADMIN' && company.id != null)
            "
            :to="`/companies/${encryptIt(this.company.id)}`"
          >
            <v-list-item-title>Company</v-list-item-title>
          </v-list-item>
          <v-list-item to="/notifications" @click="hide_bell = true">
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item
          :to="`/companies`"
          v-if="roleName == 'SUPER ADMIN' && company.id == null"
        >
          <v-list-item-icon>
            <v-icon>mdi-office-building-marker-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Companies</v-list-item-title>
        </v-list-item>
        <v-list-group
          no-action
          :value="false"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Users</v-list-item-title>
          </template>
          <v-list-item :to="`/users`">
            <v-list-item-icon>
              <v-icon>mdi-table-of-contents </v-icon>
            </v-list-item-icon>
            <v-list-item-title>List</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-account-tie-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1"
              >Vendors</v-list-item-title
            >
          </template>
          <!-- <v-list-item :to="`/prefixs`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Prefixs</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/vendor-groups`">
            <v-list-item-icon>
              <v-icon>mdi-table-of-contents </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item> -->
          <v-list-item :to="`/vendors`">
            <v-list-item-icon>
              <v-icon>mdi-account-tie-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lists</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          v-if="
            roleName == 'ADMIN' ||
            positionName == 'VENDOR' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-bank-transfer-in</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1"
              >Purchases</v-list-item-title
            >
          </template>
          <v-list-item :to="`/fabric-pos`">
            <v-list-item-icon>
              <v-icon size="25">mdi-bank-transfer-in</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Fabric POs</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Other</v-list-item-title>
          </template>

          <v-list-item :to="`/delivery-terms`">
            <v-list-item-icon>
              <v-icon size="25">mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Delivery Terms</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/term-conditions`">
            <v-list-item-icon>
              <v-icon size="25">mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Term & Conditions</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/payment-terms`">
            <v-list-item-icon>
              <v-icon size="25">mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Payment Terms</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          no-action
          :value="false"
          v-if="
            roleName == 'ADMIN' ||
            (roleName == 'SUPER ADMIN' && company.id != null)
          "
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1"
              >Settings</v-list-item-title
            >
          </template>

          <v-list-item :to="`/modules`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Modules</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/permissions`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Permissions</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/positions`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Position</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/work-locations`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Work Location</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/countries`">
            <v-list-item-icon>
              <v-icon size="25">mdi-earth</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Country</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/states`">
            <v-list-item-icon>
              <v-icon size="25">mdi-map</v-icon>
            </v-list-item-icon>
            <v-list-item-title>State</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/cities`">
            <v-list-item-icon>
              <v-icon size="25">mdi-map</v-icon>
            </v-list-item-icon>
            <v-list-item-title>City</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/shifts`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shift</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/e-invoice-settings`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>E-Invoice Setting</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/certification-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Certification Type</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/vendor-prefixs`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Vendor Prefix</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/locations`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Location</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/location-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Location Type</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/agent-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Agent Type</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/account-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account Type</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/account-sub-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account Sub Type</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/account-groups`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account Group</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/group-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Group Types</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/groups`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/gst-reg-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>GST Reg Types</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/purchase-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Purchase Types</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/bank-names`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Bank Names</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/colors`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Colors</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/weave-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Weave Types</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/coparts`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Coparts</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/mills`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mills</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/sourcing-bys`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sourcing Bys</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/rate-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-arrow-expand-all</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Rate Types</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/agents`">
            <v-list-item-icon>
              <v-icon size="25">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Agents</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/gsts`">
            <v-list-item-icon>
              <v-icon size="25">mdi-percent</v-icon>
            </v-list-item-icon>
            <v-list-item-title>GSTs</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/qualities`">
            <v-list-item-icon>
              <v-icon size="25">mdi-star-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Qualities</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/consignees`">
            <v-list-item-icon>
              <v-icon size="25">mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Consignees</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/currencies`">
            <v-list-item-icon>
              <v-icon size="25">mdi-currency-inr</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Currencies</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/stock-types`">
            <v-list-item-icon>
              <v-icon size="25">mdi-basket-unfill</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stock Types</v-list-item-title>
          </v-list-item>
          <v-list-item :to="`/transportations`">
            <v-list-item-icon>
              <v-icon size="25">mdi-bus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Transportations</v-list-item-title>
          </v-list-item>
          <section v-if="positionName != 'VENDOR'">
            <v-list-item :to="`/profile/${encryptIt(user.id)}`">
              <v-list-item-icon>
                <v-icon size="25">mdi-arrow-expand-all</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >My Profile {{ positionName }}</v-list-item-title
              >
            </v-list-item>
          </section>
          <section v-else>
            <v-list-item :to="`/vendor/${encryptIt(user.vendor.id)}`">
              <v-list-item-icon>
                <v-icon size="25">mdi-arrow-expand-all</v-icon>
              </v-list-item-icon>
              <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item>
          </section>
        </v-list-group>
        <!-- <v-list-group no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon size="25">mdi-trash-can-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-subtitle-1">Trash</v-list-item-title>
          </template>
          <v-list-item :to="`/users?open_trash=true`">
            <v-list-item-icon>
              <v-icon size="25">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
          <v-list-group
            no-action
            sub-group
            :value="false"
            v-if="
              roleName == 'ADMIN' ||
              (roleName == 'SUPER ADMIN' && company.id != null)
            "
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="`/modules?open_trash=true`" link>
              <v-list-item-icon>
                <v-icon size="25">mdi-arrow-expand-all</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Modules</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/permissions?open_trash=true`" link>
              <v-list-item-icon>
                <v-icon size="25">mdi-arrow-expand-all</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Permissions</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/positions?open_trash=true`" link>
              <v-list-item-icon>
                <v-icon size="25">mdi-arrow-expand-all</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Position</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group> -->
        <v-list-item :to="`/user-timestamps`">
          <v-list-item-icon>
            <v-icon size="25">mdi-monitor-eye</v-icon>
          </v-list-item-icon>
          <v-list-item-title>User Timestamps</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="white"
      class="dont-print-header"
      style="overflow-x: auto; overflow-y: hidden"
    >
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
        color=""
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Vuetify Name"
            class="mt-1"
            contain
            min-width="50"
            :src="
              company.logo_path
                ? `${mediaUrl + company.logo_path}`
                : require('../../assets/logo-yarn.png')
            "
            width="50"
            height="50"
        /></router-link>
      </div>
      &nbsp;
      <span class="my-auto" style="font-size: 25px; min-width: max-content">
        Hey,
        {{ user.user_name != null ? user.user_name : user.email }}
        {{ company.name ? "[ " + company.name + " ]" : "" }}
      </span>
      <v-container class="mt-5 ml-15" fluid v-if="roleName == 'SUPER ADMIN'">
        <v-switch
          inset
          @change="switch_to(company.id)"
          :loading="isSwitching"
          v-model="is_acting_admin"
          :label="`${is_acting_admin ? 'ADMIN' : 'SUPERADMIN'}`"
        ></v-switch>
      </v-container>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-if="hide_bell == false">
            <v-badge overlap color="pink" icon="mdi-bell">
              <v-avatar color="primary" v-bind="attrs" v-on="on">
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>
            </v-badge>
          </div>
          <div v-else>
            <v-avatar color="primary" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </div>
        </template>
        <v-list dense>
          <v-list-item to="/notifications" @click="hide_bell = true">
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>

          <section v-if="positionName != 'VENDOR'">
            <v-list-item
              :to="`/profile/${encryptIt(user.id)}`"
              @click="hide_bell = true"
            >
              <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item>
          </section>
          <section v-else>
            <v-list-item :to="`/vendors/${encryptIt(user.vendor.id)}`">
              <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item>
          </section>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <router-view />
    <!-- Clear Success Dialog Box -->
    <v-dialog v-model="show_company_dialog" width="850">
      <v-card rounded="xxl">
        <v-card-title> <h1>Switch to Admin</h1> </v-card-title>
        <v-card-subtitle class="my-1">
          Please select the company whose admin portal you'd like to switch to.
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-text-field
                solo
                rounded
                v-model="form.search_keyword"
                label="Search ... "
                clearable
                color="#e60040"
                @click:clear="(form.search_keyword = null), getCompanies()"
                prepend-inner-icon="mdi-magnify"
                @keydown.enter="getCompanies"
                @change="page = 1"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-row v-else>
            <v-col md="12">
              <v-simple-table fixed-header height="285" class="striped-table">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Status</th>
                      <th class="text-left" style="min-width: none">Name</th>
                      <th class="text-left">Email</th>
                      <th class="text-left">Admin Name</th>
                      <th class="text-right action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="companies.length != 0">
                    <tr
                      v-for="(company, i) in companies"
                      :key="`company_${i}`"
                      :class="{
                        'table-row-even': i % 2 === 0,
                        'table-row-odd': i % 2 !== 0,
                        'table-row-last': companies.length - 1 == i,
                      }"
                    >
                      <td style="min-width: 85px">
                        {{ i + 1 }}
                      </td>
                      <td>
                        <v-tooltip bottom v-if="company.is_active == 1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="teal"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15"
                                  >mdi-checkbox-marked-circle</v-icon
                                >
                              </v-avatar>
                              Active
                            </v-chip>
                          </template>
                          <span>Company is Active</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="company.is_active == 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="red"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon size="15">mdi-cross-circle</v-icon>
                              </v-avatar>
                              Inactive
                            </v-chip>
                          </template>
                          <span>Company is InActive</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-avatar rounded>
                          <v-img
                            alt="Vuetify Name"
                            class="mt-1"
                            contain
                            min-width="35"
                            :src="
                              company.logo_path
                                ? `${mediaUrl + company.logo_path}`
                                : require('../../assets/logo-yarn.png')
                            "
                            width="35"
                            height="35"
                          />
                        </v-avatar>
                        &nbsp;
                        {{ company.name || "" }}
                      </td>
                      <td>{{ company.email || "" }}</td>
                      <td>{{ company.admin_name || "" }}</td>
                      <td class="text-right">
                        <v-btn
                          class="ml-2"
                          fab
                          x-small
                          color="primary"
                          @click="changeCompany(company.id)"
                        >
                          <v-icon dark> mdi-account-switch-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">No data found</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";
import axios from "axios";
import store from "@/store";

export default {
  data() {
    return {
      form: {
        search_keyword: "",
      },
      hide_bell: false,
      drawer: true,
      is_acting_admin: false,
      companyName: "",
      selectedCompanyId: "",
      company_data: {},
      companies: [],
      is_company_user: false,
      isLoading: false,
      isSwitching: false,
      show_company_dialog: false,
    };
  },
  mounted() {
    let company = store.getters["auth/company"];
    if (Object.keys(company).length > 0) {
      this.selectedCompanyId = company.id;
      if (this.roleName == "SUPER ADMIN") {
        this.is_acting_admin = true;
        console.log(this.is_acting_admin);
      }
      this.is_company_user = company ? true : false;
    }
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    DisplayName() {
      let response = "";
      if (this.roleName == "USER") {
        response = this.positionName;
      } else if (this.isActingAdmin()) {
        response = "ADMIN";
      } else {
        response = this.roleName;
      }
      return response;
    },
    logout() {
      this.logOut();
      location.replace("/");
    },
    isActingAdmin() {
      let response = false;
      if (this.roleName == "SUPER ADMIN" && this.company.id != null) {
        response = true;
      }
      return response;
    },
    // Scroll back to the 1st validation error Message
    scrollToSection(selector) {
      // Example: smooth scroll to the specific section
      const element = document.querySelector(selector);
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({
        top: middle,
        behavior: "smooth",
      });
      console.log(selector);
    },
    async getCompanies() {
      this.isLoading = true;
      this.form.search_keyword = this.form.search_keyword
        ? this.form.search_keyword
        : "";
      this.form.is_active = this.form.is_active ? this.form.is_active : "";
      let companies = [];
      let query =
        "search_keyword=" +
        this.form.search_keyword +
        "&is_active=" +
        this.form.is_active;
      companies = await axios.get(`companies?${query}`);
      this.companies = companies.data.data;
      this.count = companies.data.count;
      this.isLoading = false;
    },
    changeCompany(companyId) {
      this.selectedCompanyId = companyId;
      let company = this.companies.find((s) => s.id == this.selectedCompanyId);
      if (company) {
        console.log(company);
        store.dispatch("auth/setCompany", company);
        this.is_company_user = true;
      } else {
        store.dispatch("auth/setCompany", "");
        this.is_company_user = false;
      }
      window.location.href = "/auth/login";
      this.isSwitching = false;
      this.show_company_dialog = false;
      // this.$router.push("/auth/login");
    },
    switch_to(companyId = "") {
      this.isSwitching = true;
      console.log(this.is_acting_admin);
      if (this.is_acting_admin == true) {
        console.log("CompanyID " + companyId);
        console.log("Already SuperAdmin Switching to Admin");
        this.show_company_dialog = true;
      } else {
        console.log("Already Acting Admin Switching to SuperAdmin");
        this.selectedCompanyId = null;
        this.changeCompany();
      }
    },
  },
};
</script>
<style scoped>
.nav-text {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding: 0 8px 0 20px;
}
</style>
