import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home";

// Auth
import LoginPage from "../views/auth/login.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";
import VendorRegistrationPage from "../views/auth/vendor-registration.vue";
import AgentRegistrationPage from "../views/auth/agent-registration.vue";

// Companies
import CompaniesPage from "../views/companies/index.vue";
import CompanyCreatePage from "../views/companies/create.vue";
import CompanyIdPage from "../views/companies/id.vue";

// Users
import UsersPage from "../views/users/index.vue";
import UserCreatePage from "../views/users/create.vue";
import UserIdPage from "../views/users/id.vue";
import ProfilePage from "../views/users/id.vue";
import DeviceResetRequestPage from "../views/users/device-reset-requests.vue";
import LocationResetRequestPage from "../views/users/location-reset-requests.vue";
import UsersUploadPage from "../views/users/upload.vue";

// Positions
import PositionsPage from "../views/positions/index.vue";
import PositionCreatePage from "../views/positions/create.vue";
import PositionIdPage from "../views/positions/id.vue";

// Roles
import RolesPage from "../views/roles/index.vue";
import RoleIdPage from "../views/roles/id.vue";

// Modules
import ModulesPage from "../views/modules/index.vue";
import ModuleCreatePage from "../views/modules/create.vue";
import ModuleIdPage from "../views/modules/id.vue";

// Outlets
import OutletsPage from "../views/outlets/index.vue";
import OutletCreatePage from "../views/outlets/create.vue";
import OutletIdPage from "../views/outlets/id.vue";
import OutletsUploadPage from "../views/outlets/upload.vue";

// Outlet Users
import OutletUsersPage from "../views/outlets/outlet-users/index.vue";
import OutletUserCreatePage from "../views/outlets/outlet-users/create.vue";
import OutletUserIdPage from "../views/outlets/outlet-users/id.vue";

// Pjps
import PjpsPage from "../views/pjps/index.vue";
import PjpCreatePage from "../views/pjps/create.vue";
import PjpIdPage from "../views/pjps/id.vue";
import PjpsUploadPage from "../views/pjps/upload.vue";
import PjpCopyReferencesPage from "../views/pjps/copy-pjp-references.vue";
import PjpCopyPreviousMonth from "../views/pjps/copy-pjp-prev-month.vue";

// PjpReferences
import PjpReferencesPage from "../views/pjp-references/index.vue";
import PjpReferenceCreatePage from "../views/pjp-references/create.vue";
import PjpReferenceIdPage from "../views/pjp-references/id.vue";

// Permissions
import PermissionsPage from "../views/permissions/index.vue";
import PermissionCreatePage from "../views/permissions/create.vue";
import PermissionIdPage from "../views/permissions/id.vue";

// Values
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// ValueLists
import ValueLists from "@/views/value-lists/index.vue";
import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
import ValueListId from "@/views/value-lists/id.vue";

// States
import StatePage from "@/views/states/index.vue";
import StateCreatePage from "@/views/states/create.vue";
import StateIdPage from "@/views/states/id.vue";

// Citys
import CityPage from "@/views/cities/index.vue";
import CityCreatePage from "@/views/cities/create.vue";
import CityIdPage from "@/views/cities/id.vue";

// DeliveryTerms
import DeliveryTermPage from "@/views/delivery-terms/index.vue";
import DeliveryTermCreatePage from "@/views/delivery-terms/create.vue";
import DeliveryTermIdPage from "@/views/delivery-terms/id.vue";

// TermCondition
import TermConditionPage from "@/views/term-conditions/index.vue";
import TermConditionCreatePage from "@/views/term-conditions/create.vue";
import TermConditionIdPage from "@/views/term-conditions/id.vue";

// PaymentTerm
import PaymentTermPage from "@/views/payment-terms/index.vue";
import PaymentTermCreatePage from "@/views/payment-terms/create.vue";
import PaymentTermIdPage from "@/views/payment-terms/id.vue";

// AccountSubType
import AccountSubTypePage from "@/views/account-sub-types/index.vue";
import AccountSubTypeCreatePage from "@/views/account-sub-types/create.vue";
import AccountSubTypeIdPage from "@/views/account-sub-types/id.vue";

// AccountGroup
import AccountGroupPage from "@/views/account-groups/index.vue";
import AccountGroupCreatePage from "@/views/account-groups/create.vue";
import AccountGroupIdPage from "@/views/account-groups/id.vue";

// VendorGroup
import VendorGroupPage from "@/views/vendor-groups/index.vue";
import VendorGroupCreatePage from "@/views/vendor-groups/create.vue";
import VendorGroupIdPage from "@/views/vendor-groups/id.vue";

// Vendor
import VendorPage from "@/views/vendors/index.vue";
import VendorCreatePage from "@/views/vendors/create.vue";
import VendorIdPage from "@/views/vendors/id.vue";

// Agent
import AgentPage from "@/views/agents/index.vue";
import AgentCreatePage from "@/views/agents/create.vue";
import AgentIdPage from "@/views/agents/id.vue";

// FabricPo
import FabricPoPage from "@/views/fabric-pos/index.vue";
import FabricPoCreatePage from "@/views/fabric-pos/create.vue";
import FabricPoIdPage from "@/views/fabric-pos/id.vue";

// FabricPoDispatch
import FabricPoDispatchPage from "@/views/fabric-po-dispatches/index.vue";
import FabricPoDispatchCreatePage from "@/views/fabric-po-dispatches/create.vue";
import FabricPoDispatchIdPage from "@/views/fabric-po-dispatches/id.vue";

// FabricPoDeliveryLog
import FabricPoDeliveryLogPage from "@/views/fabric-po-delivery-logs/index.vue";
import FabricPoDeliveryLogCreatePage from "@/views/fabric-po-delivery-logs/create.vue";
import FabricPoDeliveryLogIdPage from "@/views/fabric-po-delivery-logs/id.vue";

// Gst
import GstPage from "@/views/gsts/index.vue";
import GstCreatePage from "@/views/gsts/create.vue";
import GstIdPage from "@/views/gsts/id.vue";
// Quality
import QualityPage from "@/views/qualities/index.vue";
import QualityCreatePage from "@/views/qualities/create.vue";
import QualityIdPage from "@/views/qualities/id.vue";
// Consignee
import ConsigneePage from "@/views/consignees/index.vue";
import ConsigneeCreatePage from "@/views/consignees/create.vue";
import ConsigneeIdPage from "@/views/consignees/id.vue";
// Currency
import CurrencyPage from "@/views/currencies/index.vue";
import CurrencyCreatePage from "@/views/currencies/create.vue";
import CurrencyIdPage from "@/views/currencies/id.vue";
// StockType
import StockTypePage from "@/views/stock-types/index.vue";
import StockTypeCreatePage from "@/views/stock-types/create.vue";
import StockTypeIdPage from "@/views/stock-types/id.vue";

// Transportation
import TransportationPage from "@/views/transportations/index.vue";
import TransportationCreatePage from "@/views/transportations/create.vue";
import TransportationIdPage from "@/views/transportations/id.vue";

// User Timestamps
import UserTimestamps from "@/views/user-timestamps/index.vue";

const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      // Companies
      {
        path: "/companies",
        name: "CompaniesPage",
        component: CompaniesPage,
      },
      {
        path: "/companies/create",
        name: "CompanyCreatePage",
        component: CompanyCreatePage,
      },
      {
        path: "/companies/:companyId",
        name: "CompanyIdPage",
        component: CompanyIdPage,
      },
      // Users
      {
        path: "/users",
        name: "UsersPage",
        component: UsersPage,
      },
      {
        path: "/users/create",
        name: "UserCreatePage",
        component: UserCreatePage,
      },
      {
        path: "/users/upload",
        name: "UsersUploadPage",
        component: UsersUploadPage,
      },
      {
        path: "/profile/:userId",
        name: "ProfilePage",
        component: ProfilePage,
      },
      {
        path: "/users/:userId",
        name: "UserIdPage",
        component: UserIdPage,
      },
      {
        path: "/device-reset-requests",
        name: "DeviceResetRequestPage",
        component: DeviceResetRequestPage,
      },
      {
        path: "/location-reset-requests",
        name: "LocationResetRequestPage",
        component: LocationResetRequestPage,
      },
      // Positions
      {
        path: "/positions",
        name: "PositionsPage",
        component: PositionsPage,
      },
      {
        path: "/positions/create",
        name: "PositionCreatePage",
        component: PositionCreatePage,
      },
      {
        path: "/positions/:positionId",
        name: "PositionIdPage",
        component: PositionIdPage,
      },
      // Roles
      {
        path: "/roles",
        name: "RolesPage",
        component: RolesPage,
      },
      {
        path: "/roles/:roleId",
        name: "RoleIdPage",
        component: RoleIdPage,
      },
      // Modules
      {
        path: "/modules",
        name: "ModulesPage",
        component: ModulesPage,
      },
      {
        path: "/modules/create",
        name: "ModuleCreatePage",
        component: ModuleCreatePage,
      },
      {
        path: "/modules/:moduleId",
        name: "ModuleIdPage",
        component: ModuleIdPage,
      },
      // Permissions
      {
        path: "/permissions",
        name: "PermissionsPage",
        component: PermissionsPage,
      },
      {
        path: "/permissions/create",
        name: "PermissionCreatePage",
        component: PermissionCreatePage,
      },
      {
        path: "/permissions/:permissionId",
        name: "PermissionIdPage",
        component: PermissionIdPage,
      },
      // Values
      {
        path: "/values",
        name: "Values",
        component: Values,
      },
      {
        path: "/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // ValueLists
      {
        path: "/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      // Work Location
      {
        path: "/work-locations",
        name: "Work Location",
        component: ValueLists,
      },
      // Country
      {
        path: "/countries",
        name: "Country",
        component: ValueLists,
      },
      // Shift
      {
        path: "/shifts",
        name: "Shift",
        component: ValueLists,
      },
      // Certification Type
      {
        path: "/certification-types",
        name: "Certification Type",
        component: ValueLists,
      },
      // E-InvoiceSetting
      {
        path: "/e-invoice-settings",
        name: "E-Invoice Setting",
        component: ValueLists,
      },
      // Vendor Prefix
      {
        path: "/vendor-prefixs",
        name: "Vendor Prefix",
        component: ValueLists,
      },
      // Location
      {
        path: "/locations",
        name: "Location",
        component: ValueLists,
      },
      // Location Type
      {
        path: "/location-types",
        name: "Location Type",
        component: ValueLists,
      },
      // Agent Type
      {
        path: "/agent-types",
        name: "Agent Type",
        component: ValueLists,
      },
      // Account Type
      {
        path: "/account-types",
        name: "Account Type",
        component: ValueLists,
      },
      // Group Type
      {
        path: "/group-types",
        name: "Group Type",
        component: ValueLists,
      },
      // Group
      {
        path: "/groups",
        name: "Group",
        component: ValueLists,
      },
      // Prefix
      {
        path: "/prefixs",
        name: "Prefix",
        component: ValueLists,
      },
      // GST Reg Type
      {
        path: "/gst-reg-types",
        name: "GST Reg Type",
        component: ValueLists,
      },
      // Purchase Type
      {
        path: "/purchase-types",
        name: "Purchase Type",
        component: ValueLists,
      },
      // Bank Name
      {
        path: "/bank-names",
        name: "Bank Name",
        component: ValueLists,
      },
      // Color
      {
        path: "/colors",
        name: "Color",
        component: ValueLists,
      },
      // Weave
      {
        path: "/weave-types",
        name: "Weave Type",
        component: ValueLists,
      },
      // Copart
      {
        path: "/coparts",
        name: "Copart",
        component: ValueLists,
      },
      // Mill
      {
        path: "/mills",
        name: "Mill",
        component: ValueLists,
      },
      // Sourcing By
      {
        path: "/sourcing-bys",
        name: "Sourcing By",
        component: ValueLists,
      },
      // Rate Type
      {
        path: "/rate-types",
        name: "Rate Type",
        component: ValueLists,
      },
      {
        path: "/value-lists/upload",
        name: "ValueListUpload",
        component: ValueListUpload,
      },
      {
        path: "/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      {
        path: "/value-lists/:valueListId",
        name: "ValueListId",
        component: ValueListId,
      },
      // State
      {
        path: "/states",
        name: "StatePage",
        component: StatePage,
      },
      {
        path: "/states/create",
        name: "StateCreatePage",
        component: StateCreatePage,
      },
      {
        path: "/states/:stateId",
        name: "StateIdPage",
        component: StateIdPage,
      },
      // City
      {
        path: "/cities",
        name: "CityPage",
        component: CityPage,
      },
      {
        path: "/cities/create",
        name: "CityCreatePage",
        component: CityCreatePage,
      },
      {
        path: "/cities/:cityId",
        name: "CityIdPage",
        component: CityIdPage,
      },
      // DeliveryTerm
      {
        path: "/delivery-terms",
        name: "DeliveryTermPage",
        component: DeliveryTermPage,
      },
      {
        path: "/delivery-terms/create",
        name: "DeliveryTermCreatePage",
        component: DeliveryTermCreatePage,
      },
      {
        path: "/delivery-terms/:deliveryTermId",
        name: "DeliveryTermIdPage",
        component: DeliveryTermIdPage,
      },
      // TermCondition
      {
        path: "/term-conditions",
        name: "TermConditionPage",
        component: TermConditionPage,
      },
      {
        path: "/term-conditions/create",
        name: "TermConditionCreatePage",
        component: TermConditionCreatePage,
      },
      {
        path: "/term-conditions/:termConditionId",
        name: "TermConditionIdPage",
        component: TermConditionIdPage,
      },
      // PaymentTerm
      {
        path: "/payment-terms",
        name: "PaymentTermPage",
        component: PaymentTermPage,
      },
      {
        path: "/payment-terms/create",
        name: "PaymentTermCreatePage",
        component: PaymentTermCreatePage,
      },
      {
        path: "/payment-terms/:paymentTermId",
        name: "PaymentTermIdPage",
        component: PaymentTermIdPage,
      },
      // AccountSubType
      {
        path: "/account-sub-types",
        name: "AccountSubTypePage",
        component: AccountSubTypePage,
      },
      {
        path: "/account-sub-types/create",
        name: "AccountSubTypeCreatePage",
        component: AccountSubTypeCreatePage,
      },
      {
        path: "/account-sub-types/:accountSubTypeId",
        name: "AccountSubTypeIdPage",
        component: AccountSubTypeIdPage,
      },
      // AccountGroup
      {
        path: "/account-groups",
        name: "AccountGroupPage",
        component: AccountGroupPage,
      },
      {
        path: "/account-groups/create",
        name: "AccountGroupCreatePage",
        component: AccountGroupCreatePage,
      },
      {
        path: "/account-groups/:accountGroupId",
        name: "AccountGroupIdPage",
        component: AccountGroupIdPage,
      },
      // VendorGroup
      {
        path: "/vendor-groups",
        name: "VendorGroupPage",
        component: VendorGroupPage,
      },
      {
        path: "/vendor-groups/create",
        name: "VendorGroupCreatePage",
        component: VendorGroupCreatePage,
      },
      {
        path: "/vendor-groups/:vendorGroupId",
        name: "VendorGroupIdPage",
        component: VendorGroupIdPage,
      },
      // Vendor
      {
        path: "/vendors",
        name: "VendorPage",
        component: VendorPage,
      },
      {
        path: "/vendors/create",
        name: "VendorCreatePage",
        component: VendorCreatePage,
      },
      {
        path: "/vendors/:vendorId",
        name: "VendorIdPage",
        component: VendorIdPage,
      },
      // Agent
      {
        path: "/agents",
        name: "AgentPage",
        component: AgentPage,
      },
      {
        path: "/agents/create",
        name: "AgentCreatePage",
        component: AgentCreatePage,
      },
      {
        path: "/agents/:agentId",
        name: "AgentIdPage",
        component: AgentIdPage,
      },
      // Gst
      {
        path: "/gsts",
        name: "GstPage",
        component: GstPage,
      },
      {
        path: "/gsts/create",
        name: "GstCreatePage",
        component: GstCreatePage,
      },
      {
        path: "/gsts/:gstId",
        name: "GstIdPage",
        component: GstIdPage,
      },
      // Quality
      {
        path: "/qualities",
        name: "QualityPage",
        component: QualityPage,
      },
      {
        path: "/qualities/create",
        name: "QualityCreatePage",
        component: QualityCreatePage,
      },
      {
        path: "/qualities/:qualityId",
        name: "QualityIdPage",
        component: QualityIdPage,
      },
      // FabricPo
      {
        path: "/fabric-pos",
        name: "FabricPoPage",
        component: FabricPoPage,
      },
      {
        path: "/fabric-pos/create",
        name: "FabricPoCreatePage",
        component: FabricPoCreatePage,
      },
      {
        path: "/fabric-pos/:fabricPoId",
        name: "FabricPoIdPage",
        component: FabricPoIdPage,
      },
      // FabricPoDispatch
      {
        path: "/fabric-pos/:fabricPoId/fabric-po-dispatches",
        name: "FabricPoDispatchPage",
        component: FabricPoDispatchPage,
      },
      {
        path: "/fabric-pos/:fabricPoId/fabric-po-dispatches/create",
        name: "FabricPoDispatchCreatePage",
        component: FabricPoDispatchCreatePage,
      },
      {
        path: "/fabric-po-dispatches/:fabricPoDispatchId",
        name: "FabricPoDispatchIdPage",
        component: FabricPoDispatchIdPage,
      },
      // FabricPoDeliveryLog
      {
        path: "/fabric-pos/:fabricPoId/fabric-po-delivery-logs",
        name: "FabricPoDeliveryLogPage",
        component: FabricPoDeliveryLogPage,
      },
      {
        path: "/fabric-pos/:fabricPoId/fabric-po-delivery-logs/create",
        name: "FabricPoDeliveryLogCreatePage",
        component: FabricPoDeliveryLogCreatePage,
      },
      {
        path: "/fabric-pos/:fabricPoId",
        name: "FabricPoDeliveryLogIdPage",
        component: FabricPoDeliveryLogIdPage,
      },
      // Consignee
      {
        path: "/consignees",
        name: "ConsigneePage",
        component: ConsigneePage,
      },
      {
        path: "/consignees/create",
        name: "ConsigneeCreatePage",
        component: ConsigneeCreatePage,
      },
      {
        path: "/consignees/:consigneeId",
        name: "ConsigneeIdPage",
        component: ConsigneeIdPage,
      },
      // Currency
      {
        path: "/currencies",
        name: "CurrencyPage",
        component: CurrencyPage,
      },
      {
        path: "/currencies/create",
        name: "CurrencyCreatePage",
        component: CurrencyCreatePage,
      },
      {
        path: "/currencies/:currencyId",
        name: "CurrencyIdPage",
        component: CurrencyIdPage,
      },
      // StockType
      {
        path: "/stock-types",
        name: "StockTypePage",
        component: StockTypePage,
      },
      {
        path: "/stock-types/create",
        name: "StockTypeCreatePage",
        component: StockTypeCreatePage,
      },
      {
        path: "/stock-types/:stockTypeId",
        name: "StockTypeIdPage",
        component: StockTypeIdPage,
      },
      // Transportation
      {
        path: "/transportations",
        name: "TransportationPage",
        component: TransportationPage,
      },
      {
        path: "/transportations/create",
        name: "TransportationCreatePage",
        component: TransportationCreatePage,
      },
      {
        path: "/transportations/:transportationId",
        name: "TransportationIdPage",
        component: TransportationIdPage,
      },
      // Outlets
      {
        path: "/outlets",
        name: "OutletsPage",
        component: OutletsPage,
      },
      {
        path: "/outlets/upload",
        name: "OutletsUploadPage",
        component: OutletsUploadPage,
      },
      {
        path: "/outlets/create",
        name: "OutletCreatePage",
        component: OutletCreatePage,
      },
      {
        path: "/outlets/:outletId",
        name: "OutletIdPage",
        component: OutletIdPage,
      },
      // OutletUsers
      {
        path: "/outlets/:outletId/outlet-users",
        name: "OutletUsersPage",
        component: OutletUsersPage,
      },
      {
        path: "/outlets/:outletId/outlet-users/create",
        name: "OutletUserCreatePage",
        component: OutletUserCreatePage,
      },
      {
        path: "/outlet-users/:outletUserId",
        name: "OutletUserIdPage",
        component: OutletUserIdPage,
      },
      // Pjps
      {
        path: "/pjps",
        name: "PjpsPage",
        component: PjpsPage,
      },
      {
        path: "/pjps/upload",
        name: "PjpsUploadPage",
        component: PjpsUploadPage,
      },
      {
        path: "/pjps/copy-pjp-references",
        name: "PjpCopyReferencesPage",
        component: PjpCopyReferencesPage,
      },
      {
        path: "/pjps/copy-pjp-prev-month",
        name: "PjpCopyPreviousMonth",
        component: PjpCopyPreviousMonth,
      },
      {
        path: "/pjps/create",
        name: "PjpCreatePage",
        component: PjpCreatePage,
      },
      {
        path: "/pjps/:pjpId",
        name: "PjpIdPage",
        component: PjpIdPage,
      },
      // PjpReferences
      {
        path: "/pjp-references",
        name: "PjpReferencesPage",
        component: PjpReferencesPage,
      },
      {
        path: "/pjp-references/create",
        name: "PjpReferenceCreatePage",
        component: PjpReferenceCreatePage,
      },
      {
        path: "/pjp-references/:pjpReferenceId",
        name: "PjpReferenceIdPage",
        component: PjpReferenceIdPage,
      },
      // UserTimestamps
      {
        path: "/user-timestamps",
        name: "UserTimestamps",
        component: UserTimestamps,
      },
    ],
  },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/vendor-registration",
        name: "VendorRegistrationPage",
        component: VendorRegistrationPage,
      },
      {
        path: "/agent-registration",
        name: "AgentRegistrationPage",
        component: AgentRegistrationPage,
      },
    ],
  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters["auth/authenticated"] && from.name) {
    to.params.startTime = new Date();
  }
  if (
    to.name === "LoginPage" ||
    to.name === "RegisterPage" ||
    to.name === "VendorRegistrationPage" ||
    to.name === "AgentRegistrationPage" ||
    to.name === "ForgotPasswordPage"
  ) {
    console.log("1");
    if (store.getters["auth/authenticated"]) {
      console.log("2");
      let company_id = store.getters["auth/company"].id;
      if (company_id) {
        console.log("3");
        console.log(company_id);
      }
      return next({
        name: "HomePage",
      });
    }
    next();
  } else if (!store.getters["auth/authenticated"]) {
    console.log("4");
    return next({
      name: "LoginPage",
    });
  } else if (to.name === null) {
    console.log("5");
    return next({
      name: "HomePage",
    });
  }
  next();
});

router.afterEach(async (to, from) => {
  if (
    store.getters["auth/authenticated"] &&
    from.name &&
    !to.name.includes("Create") &&
    !to.name.includes("Edit")
  ) {
    let endTime = new Date();
    let startTime = from.params.startTime;
    let timeSpentInSeconds = "0";
    if (startTime) {
      timeSpentInSeconds = (endTime - startTime) / 1000;
    }
    if (timeSpentInSeconds > 1200) {
      timeSpentInSeconds = 1200;
    }
    console.log(
      `Time spent on "${to.name}" route: ${timeSpentInSeconds} seconds`
    );
    let form = {
      from_path: from.path,
      from_name: from.name,
      user_id: store.getters["auth/user"].id,
      timespent: timeSpentInSeconds,
    };
    await axios.post(`/user_timestamps`, form);
  }
});

export default router;
