<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="6" rounded="xxl">
        <v-card-title class="mt-4">
          <v-btn
            class="mr-2"
            fab
            x-small
            color="primary"
            @click="$router.back()"
          >
            <v-icon dark> mdi-arrow-left </v-icon> </v-btn
          >Edit Fabric PO
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="4">
              <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date"
                    label="Date *"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click:clear="form.date = null"
                    :error-messages="errors.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date"
                  :min="current_date"
                  @change="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="vendor_id"
                outlined
                dense
                v-model="form.vendor_id"
                :items="vendorItems"
                label="Vendor *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.vendor_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="gst_type"
                outlined
                dense
                v-model="form.gst_type"
                :items="gstTypeItems"
                label="GST Type"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.gst_type"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="agent_id"
                outlined
                dense
                v-model="form.agent_id"
                :items="agentItems"
                label="Agent Name *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.agent_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-text-field
                v-model="form.customer_so"
                outlined
                dense
                label="Customer SO"
                prepend-inner-icon="mdi-card-text-outline"
                :error-messages="errors.customer_so"
              ></v-text-field>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="term_condition_id"
                outlined
                dense
                v-model="form.term_condition_id"
                :items="termConditionItems"
                label="Terms & Condition"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.term_condition_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="fabric_type"
                outlined
                dense
                v-model="form.fabric_type"
                :items="fabricTypeItems"
                label="Fabric Type"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.fabric_type"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="consignee_id"
                outlined
                dense
                v-model="form.consignee_id"
                :items="consigneeItems"
                label="Consignee"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.consignee_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="payment_term_id"
                outlined
                dense
                v-model="form.payment_term_id"
                :items="paymentTermItems"
                label="Payment Terms *"
                prepend-inner-icon="mdi-earth"
                clearable
                :error-messages="errors.payment_term_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <v-autocomplete
                ref="transportation_id"
                outlined
                dense
                v-model="form.transportation_id"
                :items="transportationItems"
                label="Transportation *"
                prepend-inner-icon="mdi-bus"
                clearable
                :error-messages="errors.transportation_id"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4" v-if="roleName == 'ADMIN'">
              <v-autocomplete
                ref="approver_status"
                outlined
                dense
                v-model="form.approver_status"
                :items="approverStatuses"
                label="Approver Status *"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.status"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4" v-else>
              <v-autocomplete
                ref="vendor_approval_status"
                outlined
                dense
                v-model="form.vendor_approval_status"
                :items="approverStatuses"
                label="Approval Status *"
                prepend-inner-icon="mdi-earth"
                :error-messages="errors.vendor_approval_status"
              ></v-autocomplete>
            </v-col>
            <v-col sm="4">
              <label for="Pan">Upload Delivery Flow</label>
              <br />
              <input
                label="Delivery Flow"
                type="file"
                id="delivery_flow_file"
                name="delivery_flow_file"
                :ref="`delivery_flow_file`"
              />
              <v-btn
                x-small
                class="reset_image my-1"
                color="red"
                dark
                @click="
                  ($refs.delivery_flow_file.value = null),
                    (delivery_flow_image_url = null)
                "
                >Remove</v-btn
              >
            </v-col>
            <v-col sm="4">
              <v-switch
                class="mt-0 ml-5"
                v-model="form.is_active"
                inset
                :label="`Is Active: ${form.is_active ? 'YES' : 'NO'}`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="my-5"> </v-divider>
          <template v-if="form.fabric_po_items.length">
            <h3 class="my-4">List of Item(s) for this Fabric PO:</h3>
            <section
              v-for="(item, fpI) in form.fabric_po_items"
              :key="`item${fpI}`"
            >
              <v-row>
                <v-col sm="4">
                  <v-autocomplete
                    ref="quality"
                    outlined
                    dense
                    v-model="item.quality_id"
                    :items="qualityItems"
                    label="Quality *"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="
                      errors[`fabric_po_items.${fpI}.quality_id`]
                        ? errors[`fabric_po_items.${fpI}.quality_id`]
                        : ``
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.quantity"
                    outlined
                    dense
                    label="Quantity"
                    @input="Calculate(item)"
                    prepend-inner-icon="mdi-bank"
                    :error-messages="
                      errors[`fabric_po_items.${fpI}.quantity`]
                        ? errors[`fabric_po_items.${fpI}.quantity`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-autocomplete
                    ref="country"
                    outlined
                    dense
                    v-model="item.quantity_uom"
                    :items="uomItems"
                    label="UOM"
                    prepend-inner-icon="mdi-earth"
                    clearable
                    :error-messages="
                      errors[`fabric_po_items.${fpI}.quantity_uom`]
                        ? errors[`fabric_po_items.${fpI}.quantity_uom`]
                        : ``
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.unit_price"
                    outlined
                    dense
                    @input="Calculate(item)"
                    label="Unit Price *"
                    prepend-inner-icon="mdi-bank"
                    append-icon="mdi-currency-inr"
                    :error-messages="
                      errors[`fabric_po_items.${fpI}.unit_price`]
                        ? errors[`fabric_po_items.${fpI}.unit_price`]
                        : ``
                    "
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.basic_amount"
                    outlined
                    dense
                    @input="Calculate(item)"
                    label="Basic Amount"
                    prepend-inner-icon="mdi-bank"
                    append-icon="mdi-currency-inr"
                  ></v-text-field>
                </v-col>
                <template v-if="form.gst_type == 1">
                  <v-col sm="2">
                    <v-autocomplete
                      ref="igst"
                      outlined
                      dense
                      @change="Calculate(item)"
                      v-model="item.igst_percent"
                      :items="igstItems"
                      label="IGST % *"
                      prepend-inner-icon="mdi-earth"
                      clearable
                      :error-messages="
                        errors[`fabric_po_items.${fpI}.igst_percent`]
                          ? errors[`fabric_po_items.${fpI}.igst_percent`]
                          : ``
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.igst_amount"
                      outlined
                      readonly
                      dense
                      label="IGST Amount"
                      prepend-inner-icon="mdi-bank"
                      append-icon="mdi-currency-inr"
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-else>
                  <v-col sm="2">
                    <v-autocomplete
                      ref="cgst"
                      outlined
                      dense
                      v-model="item.cgst_percent"
                      :items="cgstItems"
                      @change="Calculate(item)"
                      label="CGST % *"
                      prepend-inner-icon="mdi-earth"
                      clearable
                      :error-messages="
                        errors[`fabric_po_items.${fpI}.cgst_percent`]
                          ? errors[`fabric_po_items.${fpI}.cgst_percent`]
                          : ``
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.cgst_amount"
                      outlined
                      readonly
                      dense
                      label="CGST Amount"
                      prepend-inner-icon="mdi-bank"
                      append-icon="mdi-currency-inr"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="2">
                    <v-autocomplete
                      ref="sgst"
                      outlined
                      dense
                      v-model="item.sgst_percent"
                      @change="Calculate(item)"
                      :items="sgstItems"
                      label="SGST % *"
                      prepend-inner-icon="mdi-earth"
                      clearable
                      :error-messages="
                        errors[`fabric_po_items.${fpI}.sgst_percent`]
                          ? errors[`fabric_po_items.${fpI}.sgst_percent`]
                          : ``
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col sm="2">
                    <v-text-field
                      v-model="item.sgst_amount"
                      outlined
                      readonly
                      dense
                      label="SGST Amount"
                      prepend-inner-icon="mdi-bank"
                      append-icon="mdi-currency-inr"
                    ></v-text-field>
                  </v-col>
                </template>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.total_amount"
                    outlined
                    readonly
                    dense
                    label="Total Amount"
                    prepend-inner-icon="mdi-bank"
                    append-icon="mdi-currency-inr"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.delivery_terms"
                    outlined
                    readonly
                    dense
                    label="Delivery Terms"
                    prepend-inner-icon="mdi-bank"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.provisional_freight"
                    outlined
                    dense
                    @input="CalculateFinalPrice(item)"
                    label="Provisional Freight"
                    prepend-inner-icon="mdi-bank"
                    append-icon="mdi-currency-inr"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.mill_gst_price"
                    outlined
                    readonly
                    dense
                    label="Mill GST Price"
                    prepend-inner-icon="mdi-bank"
                    append-icon="mdi-currency-inr"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.final_price"
                    outlined
                    readonly
                    dense
                    label="Final Price"
                    prepend-inner-icon="mdi-bank"
                    append-icon="mdi-currency-inr"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-menu
                    v-model="item.delivery_date_menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.delivery_date"
                        label="Delivery Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="item.delivery_date = null"
                        :error-messages="errors.date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.delivery_date"
                      :min="current_date"
                      @change="item.delivery_date_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    v-model="item.remark"
                    outlined
                    dense
                    label="Remark"
                    prepend-inner-icon="mdi-bank"
                  ></v-text-field>
                </v-col>
                <v-col sm="1">
                  <v-dialog v-model="isDeleteItemDialogs[p]" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-9"
                        small
                        dark
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <v-card elevation="10">
                      <v-card-title>
                        <v-icon large color="primary">
                          mdi-information-outline
                        </v-icon>
                        &nbsp; Do you want to remove the section?
                      </v-card-title>
                      <v-card-text>
                        <v-btn color="primary" @click.stop="deleteItem(item)">
                          Yes
                        </v-btn>
                        &nbsp;
                        <v-btn
                          color="red"
                          dark
                          @click.stop="isDeleteItemDialogs = []"
                        >
                          No
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col sm="12">
                  <v-autocomplete
                    outlined
                    ref="Certification Type"
                    v-model="item.certification_types"
                    :items="certificationTypeItems"
                    label="Select Certification Type"
                    prepend-inner-icon="mdi-database-search"
                    multiple
                    chips
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-divider class="my-5"> </v-divider>
            </section>
          </template>
          <v-row>
            <v-col sm="12" class="mb-5">
              <v-btn small rounded color="primary" @click="Add_Empty_Item">
                Add Item
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"> </v-divider>
          <v-btn
            rounded
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="save"
          >
            Update Fabric PO
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "FabricPOEditPage",
  data() {
    return {
      form: {
        date: "",
        name: "",
        gst_type: 1,
        is_active: 1,
        fabric_po_items: [],
      },
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      date_menu: false,
      vendorGroupItems: [],
      vendorItems: [],
      agentItems: [],
      termConditionItems: [],
      paymentTermItems: [],
      qualityItems: [],
      deliveryTermItems: [],
      transportationItems: [],
      certificationTypeItems: [],
      soItems: [],
      companyTypeItems: [],
      consigneeItems: [],
      igstItems: [],
      cgstItems: [],
      sgstItems: [],
      isDeleteItemDialogs: [],
      isDeleteCondition_TypeDialogs: [],
      fabricTypeItems: [
        { id: "FINISHED", text: "FINISHED", value: 1 },
        { id: "GREY", text: "GREY", value: 2 },
      ],
      gstTypeItems: [
        { id: "IGST", text: "IGST", value: 1 },
        { id: "CGST/SGST", text: "CGST/SGST", value: 2 },
      ],
      uomItems: [
        { id: "MTR", text: "MTR", value: 1 },
        { id: "KG", text: "KG", value: 2 },
        { id: "PIECES", text: "PIECES", value: 3 },
      ],
      approverStatuses: [
        { id: "PENDING", text: "PENDING", value: 0 },
        { id: "APPROVED", text: "APPROVED", value: 1 },
        { id: "REJECTED", text: "REJECTED", value: 2 },
      ],
      purchaseTypeItems: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    // this.getData();
  },
  methods: {
    async getMasters() {
      this.isLoading = true;
      await axios
        .get(`fabric_pos/masters`)
        .then((masters) => {
          masters = masters.data;
          //Vendors
          masters.vendors.forEach((v) => {
            this.vendorItems.push({
              id: v.id,
              text: v.name,
              value: v.id,
            });
          });
          //Transportations
          masters.transportations.forEach((t) => {
            this.transportationItems.push({
              id: t.id,
              text: t.name,
              value: t.id,
            });
          });
          //Agents
          masters.agents.forEach((agent) => {
            this.agentItems.push({
              id: agent.id,
              text: agent.name,
              value: agent.id,
            });
          });
          //Agents
          masters.consignees.forEach((consignee) => {
            this.consigneeItems.push({
              id: consignee.id,
              text: consignee.name,
              value: consignee.id,
            });
          });
          //Term Conditions
          masters.term_conditions.forEach((term_condition) => {
            this.termConditionItems.push({
              id: term_condition.id,
              text: term_condition.name,
              value: term_condition.id,
            });
          });
          //Payment Terms
          masters.payment_terms.forEach((payment_term) => {
            this.paymentTermItems.push({
              id: payment_term.id,
              text: payment_term.name,
              value: payment_term.id,
            });
          });
          //GST
          masters.gsts.forEach((gst) => {
            if (gst.gst_type == 1) {
              this.igstItems.push({
                id: gst.id,
                text: gst.igst_percent,
                value: gst.igst_percent,
                gst_type: gst.gst_type,
              });
            } else {
              this.cgstItems.push({
                id: gst.id,
                text: gst.cgst_percent,
                value: gst.cgst_percent,
                gst_type: gst.gst_type,
              });
              this.sgstItems.push({
                id: gst.id,
                text: gst.sgst_percent,
                value: gst.sgst_percent,
                gst_type: gst.gst_type,
              });
            }
          });
          //Delivery Terms
          masters.qualities.forEach((quality) => {
            this.qualityItems.push({
              id: quality.id,
              text: quality.quality_description,
              value: quality.id,
            });
          });
          //Delivery Terms
          masters.delivery_terms.forEach((delivery_term) => {
            this.deliveryTermItems.push({
              id: delivery_term.id,
              text: delivery_term.name,
              value: delivery_term.id,
            });
          });
          //Certification Types
          masters.certification_types.forEach((certification_type) => {
            this.certificationTypeItems.push({
              id: certification_type.id,
              text: certification_type.description,
              value: certification_type.id,
            });
          });
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          // redirect to Home Page
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    Add_Empty_Item() {
      let fabric_po_item = {
        igst_percent: "",
        igst_amount: 0,
        cgst_percent: "",
        cgst_amount: 0,
        sgst_percent: "",
        sgst_amount: 0,
        gst_amount: 0,
        delivery_date: "",
        delivery_date_menu: false,
        certification_types: [],
        fabric_po_item_yarn_certification_types: [],
      };
      this.form.fabric_po_items.push(fabric_po_item);
    },
    deleteItem(fabric_po_item) {
      this.form.fabric_po_items.splice(
        this.form.fabric_po_items.indexOf(fabric_po_item),
        1
      );
      this.isDeleteItemDialogs = [];
    },
    Calculate(item) {
      item.gst_amount = 0;
      console.log("Hit Calculation");
      this.CalculateBasicAmount(item);
      console.log("BASIC: " + item.basic_amount);
      if (this.form.gst_type == 1) {
        this.CalculateIGSTAmount(item);
        console.log("IGST AMOUNT: " + item.igst_amount);
      } else {
        this.CalculateCGSTAmount(item);
        console.log("CGST AMOUNT: " + item.cgst_amount);
        this.CalculateSGSTAmount(item);
        console.log("SGST AMOUNT: " + item.sgst_amount);
      }
      item.gst_amount =
        parseFloat(item.igst_amount) +
        parseFloat(item.cgst_amount) +
        parseFloat(item.sgst_amount);
      console.log("Total GST AMOUNT: " + item.gst_amount);
      this.CalculateTotalAmount(item);
      console.log("Total AMOUNT: " + item.total_amount);
      this.CalculateMillGSTAmount(item);
      console.log("Mill GST AMOUNT: " + item.mill_gst_price);
      this.CalculateFinalPrice(item);
      console.log("Final Price: " + item.final_price);
    },
    CalculateBasicAmount(item) {
      let basic_amount = 0;
      if (item.quantity && item.unit_price) {
        basic_amount = parseFloat(item.quantity) * parseFloat(item.unit_price);
      }
      item.basic_amount = parseFloat(basic_amount);
    },
    CalculateIGSTAmount(item) {
      let igst_amount = 0;
      if (item.basic_amount && item.igst_percent) {
        igst_amount =
          parseFloat(
            parseFloat(item.basic_amount) * parseFloat(item.igst_percent)
          ) / 100;
      }
      item.igst_amount = parseFloat(igst_amount);
    },
    CalculateCGSTAmount(item) {
      let cgst_amount = 0;
      if (item.basic_amount && item.cgst_percent) {
        cgst_amount =
          parseFloat(
            parseFloat(item.basic_amount) * parseFloat(item.cgst_percent)
          ) / 100;
      }
      item.cgst_amount = parseFloat(cgst_amount);
    },
    CalculateSGSTAmount(item) {
      let sgst_amount = 0;
      if (item.basic_amount && item.sgst_percent) {
        sgst_amount =
          parseFloat(
            parseFloat(item.basic_amount) * parseFloat(item.sgst_percent)
          ) / 100;
      }
      item.sgst_amount = parseFloat(sgst_amount);
    },
    CalculateTotalAmount(item) {
      let total_amount = 0;
      if (item.basic_amount && item.gst_amount) {
        total_amount =
          parseFloat(item.basic_amount) + parseFloat(item.gst_amount);
      }
      item.total_amount = parseFloat(total_amount);
    },
    CalculateMillGSTAmount(item) {
      let mill_gst_price = 0;
      if (item.gst_amount) {
        mill_gst_price = parseFloat(item.gst_amount) / 100;
      }
      item.mill_gst_price = parseFloat(mill_gst_price);
    },
    CalculateFinalPrice(item) {
      let final_price = 0;
      let mill_gst_price = parseFloat(
        item.mill_gst_price ? item.mill_gst_price : 0
      );
      let unit_price = parseFloat(item.unit_price ? item.unit_price : 0);
      let provisional_freight = item.provisional_freight
        ? parseFloat(item.provisional_freight)
        : 0;
      final_price = mill_gst_price + unit_price + provisional_freight;
      item.final_price = parseFloat(final_price);
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.fabricPoId;
      axios
        .get(`/fabric_pos/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.old_json = response.data.data;
          this.form.fabric_po_items.forEach((item) => {
            let certification_types = [];
            item.fabric_po_item_yarn_certification_types.forEach((yarn) => {
              certification_types.push(yarn.yarn_certification_type_id);
            });
            certification_types = [...new Set(certification_types)];
            item.certification_types = certification_types;
          });
          console.log(this.form);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.fabric_po_items.forEach((item) => {
          item.certification_types.forEach((yarn_certification_type_id) => {
            let yarn_certification_type = {
              yarn_certification_type_id: yarn_certification_type_id,
            };
            item.fabric_po_item_yarn_certification_types.push(
              yarn_certification_type
            );
          });
          item.fabric_po_item_yarn_certification_types = [
            ...new Set(item.fabric_po_item_yarn_certification_types),
          ];
        });
        await axios
          .post("/fabric_pos", this.form)
          .then(async (response) => {
            this.form = response.data.data;
            let attachment = this.$refs.delivery_flow_file.files[0];

            await this.handleFileUpload(
              this.form.id,
              "delivery_flow_filepath",
              attachment,
              "upload_fabric_po_delivery_flow_filepath"
            );
            this.isLoading = false;
            await this.saveUserTimestamp(null, this.form);
            this.$router.push("/fabric-pos");
          })
          .catch((error) => {
            // redirect to Home Page
            window.code.href = "/auth/login";
            console.error("Error fetching data:", error);
          });
      } catch (e) {
        // alert("error");
        console.log(e);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
