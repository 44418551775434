<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-title>
              <h3 class="font-weight-bold" v-if="fabric_po">
                Delivery Logs for Fabric PO [ID: {{ fabric_po.id }}]
                <v-btn
                  class="mx-2 mb-1"
                  :loading="isSaving"
                  :disabled="isSaving"
                  rounded
                  color="primary"
                  @click="save"
                >
                  Save
                </v-btn>
              </h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    solo
                    rounded
                    v-model="filter.search_keyword"
                    label="Search ... "
                    color="#e60040"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    @click:clear="
                      (filter.search_keyword = null), (page = 1), getData()
                    "
                    @keydown.enter="(page = 1), getData()"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col sm="3">
                  <v-autocomplete
                    ref="status"
                    solo
                    rounded
                    v-model="filter.is_active"
                    :items="statuses"
                    clearable
                    @click:clear="(filter.is_active = null), (page = 1)"
                    label="Status"
                    @input="getData"
                    prepend-inner-icon="mdi-toggle-switch"
                  ></v-autocomplete>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card elevation="6" rounded="xxl">
            <v-card-text>
              <section v-if="isLoading" style="height: 285px">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </section>
              <v-simple-table
                v-else
                fixed-header
                :height="`${delivery_logs.length ? '370px' : ''}`"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Sr No</th>
                      <th class="text-left">Date</th>
                      <th class="text-left">Meter</th>
                      <th class="text-center action_th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(delivery_log, i) in delivery_logs"
                      :key="`delivery_log${i}`"
                    >
                      <td>{{ i + 1 }}</td>
                      <td>
                        <v-col class="px-0">
                          <v-menu
                            v-model="delivery_log.date_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="delivery_log.date"
                                label="Date *"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details="auto"
                                @click:clear="delivery_log.date = null"
                                :error-messages="
                                  delivery_log.date == '' ||
                                  delivery_log.date == null
                                    ? 'this date field is required'
                                    : ''
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="delivery_log.date"
                              :min="current_date"
                              @change="delivery_log.date_menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </td>
                      <td>
                        <v-col class="px-0">
                          <v-text-field
                            :error-messages="
                              delivery_log.meters == ''
                                ? 'this meters field is required'
                                : ''
                            "
                            v-model="delivery_log.meters"
                            outlined
                            dense
                            label="Meter *"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </td>

                      <td class="text-right">
                        <div class="text-center">
                          <v-tooltip bottom v-if="!delivery_log.id">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="primary"
                                icon
                                @click="deleteDeliveryLog(delivery_log)"
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="primary"
                                icon
                                @click="Delete_this(delivery_log.id)"
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row>
                <v-col>
                  <v-btn
                    class="my-2"
                    outlined
                    block
                    color="primary"
                    @click="addEmptyDeliveryLog()"
                  >
                    Add New Row
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Clear Success Dialog Box -->
      <v-dialog v-model="is_deleted_delivery_log" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-delete-empty
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Delivery Log deleted successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_deleted_delivery_log = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Saved Success Dialog Box -->
      <v-dialog v-model="is_saved_delivery_log" width="600">
        <v-card rounded="xxl">
          <v-container>
            <v-row align="center">
              <v-col sm="3" align="center">
                <v-icon
                  large
                  style="color: green; font-size: 90px; width: 200px !important"
                >
                  mdi-check-decagram-outline
                </v-icon>
              </v-col>
              <v-col sm="9" align="center">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h1 style="color: green">Hurray!</h1>
                      <br />
                      <h3>Delivery Log Saved successfully.</h3>
                      <br />
                      <v-btn
                        color="success"
                        @click="is_saved_delivery_log = false"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "DeliveryLogsPage",
  data() {
    return {
      filter: {
        search_keyword: "",
        is_active: "",
      },
      delivery_logs: [],
      statuses: [
        {
          id: 0,
          text: "Select Active Status",
          value: "",
        },
        { id: "ACTIVE", text: "ACTIVE", value: "1" },
        { id: "INACTIVE", text: "INACTIVE", value: "0" },
      ],
      fabric_po: {},
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      isLoading: false,
      isSaving: false,
      delete_delivery_log: false,
      is_deleted_delivery_log: false,
      is_saved_delivery_log: false,
    };
  },
  watch: {
    page: "getData",
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let encryptedId = this.$route.params.fabricPoId;
      axios
        .get(`/fabric_pos/${encryptedId}`)
        .then((response) => {
          this.fabric_po = response.data.data;
          this.filter.fabric_po_id = this.fabric_po.id;
          this.getData();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    async getData() {
      this.isLoading = true;
      this.filter.search_keyword = this.filter.search_keyword
        ? this.filter.search_keyword
        : "";
      this.filter.is_active = this.filter.is_active
        ? this.filter.is_active
        : "";
      let query =
        "is_active=" +
        this.filter.is_active +
        "&fabric_po_id=" +
        this.filter.fabric_po_id +
        "&search_keyword=" +
        this.filter.search_keyword;
      await axios
        .get(`fabric_po_delivery_logs?${query}`)
        .then((response) => {
          this.delivery_logs = response.data.data;
          this.count = response.data.count;
          this.serialNoStarting = (this.page - 1) * this.rowsPerPage;
          this.paginationLength = Math.ceil(this.count / this.rowsPerPage);
        })
        .catch(function (error) {
          console.log(error);
          // window.location.href = "/auth/login";
        });
      this.isLoading = false;
    },
    addEmptyDeliveryLog() {
      this.delivery_logs.push({
        fabric_po_id: this.fabric_po.id,
        company_id: this.company.id,
        date: "",
        meters: "",
      });
    },
    deleteDeliveryLog(delivery_log, index) {
      // Check if both description and code are empty
      if (!delivery_log.date.trim() && !delivery_log.meters.trim()) {
        // If both are empty, simply remove the row from the delivery_logs array
        this.delivery_logs.splice(index, 1);
      } else {
        // If at least one field is not empty, proceed with the API call
        this.Delete_this(delivery_log.id);
      }
    },
    async Delete_this(id) {
      this.delete_delivery_log = true;
      await axios
        .post(`/fabric_po_delivery_logs/delete/${this.encryptIt(id)}`)
        .then(() => {
          this.delete_delivery_log = false;
          this.is_deleted_delivery_log = true;
          this.getData();
        })
        .catch(function (error) {
          console.log(error);
          window.location.href = "/auth/login";
        });
    },
    async save() {
      if (this.delivery_logs.length > 0) {
        this.isSaving = true;
        let payload = {
          datas: this.delivery_logs,
        };

        let response = await axios.post(
          `/fabric_pos/${this.fabric_po.id}/fabric_po_delivery_log_multiple`,
          payload
        );
        this.delivery_logs = response.data.data;
        this.isSaving = false;
        this.is_saved_delivery_log = true;
        setTimeout(() => {
          this.is_saved_delivery_log = false;
        }, 1000);
      }
    },
  },
};
</script>
